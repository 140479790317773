/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from '../../../components/seo';

import { PAGES } from '../../../constants/Languages';
import { PAGES_LINKS } from '../../../constants/pagesLinks';


// Components
import Stocks from '../../../components/Common/Stocks/Stocks';
import layoutContext from '../../../components/layoutContext';

const Categories = ({ data }) => {
  useContext(layoutContext).setCult('uk');
  const { stocks } = data;
  const { edges } = stocks;

  const stocksPosts = edges.reduce((pages, article) => {
    if (article.node.frontmatter.image) {
      const {
        node: {
          frontmatter: {
            headLine, path, lang, category, promoDescription,
            image: { childImageSharp: { resize: { src } } }
          }
        }
      } = article;
      if (lang === 'uk' && category === 'stocks') {
        pages.push({
          headLine,
          path,
          promoDescription,
          image: src
        });
      }
    }
    return pages;
  }, []);

  return (
    <>
      <Seo
        title={PAGES.stocks['uk']}
        path={`/uk${PAGES_LINKS['stocks']}`}
      />
      <Stocks posts={stocksPosts} title={PAGES.stocks['uk']} />
    </>
  );
};

Categories.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
              promoDescription: PropTypes.string.isRequired
            })
          })
        }).isRequired
      )
    })
  })
};

export default Categories;

export const pageQuery = graphql`
  {
    stocks: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/news/" } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            headLine
            path
            lang
            category
            promoDescription
            date
            image {
              childImageSharp {
                resize(width: 350, height: 220) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
